@import '../../../styles/scss/base/palette.scss';
@import '../../../styles/scss/base/breakpoints.scss';

.layout-home-footer {
    width: 100%;
    min-height: 400px;
    background-color: $desertStorm;
    color: $abbey;
    text-align: justify;
    padding: 0 20px;
}

.content-home-footer {
    padding-top: 59px;
    padding-bottom: 53px;

    .bold-text {
        font-family: 'Gotham-Bold';
    } 

    @include for_breakpoint(xl) {
        max-width: 1140px !important;
    }

    p {
        font-size: 12px;
        font-family: Gotham-Book;
        margin-bottom: 23px;
    }
}