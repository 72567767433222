@import '../../styles/scss/base/palette.scss';
@import '../../styles/scss/base/breakpoints.scss';

.header-fia-wrapper {
    .title-guaranteed-income {
        &-detail {
            @include for_breakpoint(sm) {
                br {
                    display: none;
                }
            }

            @include for_breakpoint(xs) {
                br {
                    display: none;
                }
            }
        }

        @include for_breakpoint(md) {
            br {
                display: none;
            }
        }
    }

    .title-results {
        margin-bottom: 1.5%;
        margin-top: 3%;

        @include for_breakpoint(md) {
            br {
                display: none;
            }
        }

        @include for_breakpoint(sm) {
            br {
                display: none;
            }
        }

        @include for_breakpoint(xs) {
            font-size: 12px;
            margin-bottom: 3.5%;

            br {
                display: none;
            }
        }
    }

    .first-card, .second-card {
        @include for_breakpoint(xs) {
            div:first-child {
                font-size: 36px !important;
            }
        }
    }

    p.too-long-text {
        width: 100% !important;

        span {
            font-size: 48px !important;
        }
    }
}