@import '../../../styles/scss/base/palette.scss';
@import '../../../styles/scss/base/breakpoints.scss';

.header-container {
	width: 100%;
	min-height: 598px;
	background-image: linear-gradient(180deg, $white 0%, $athensGray 100%);

	.header-content {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.header-breadcrumbs-wrapper {
		padding-bottom: 2rem;
	}

	&-HeaderFIA {
		min-height: 624px;

		@include for_breakpoint(xs) {
			min-height: 470px;
		}
	}

	&-HeaderVA {
		min-height: 390px;

		@include for_breakpoint(xs) {
			min-height: 260px;
		}

		@include for_breakpoint(sm) {
			min-height: 410px;
		}

		@include for_breakpoint(md) {
			min-height: 350px;
		}
	}
}

.body-container {
    padding-top: 5rem;

    .results-buttons-container {
        margin-top: 5em;
        margin-bottom: 5em;

        &.va {
            margin-top: 0;

            button {
                background-color: transparent;
                color: $cobalt;
                &:hover {
                    color: $white;
                    background-color: $cobalt !important;
                }
                &.run-new {
                    color: $white;
                    background-color: $deepSea;
                    &:hover {
                        background-color: $darkCelurian !important;
                    }
                }

                @media (max-width: 768px) {
                    width: 100% !important;
                    margin-bottom: 10px;
                }

                @media (max-width: 400px) {
                    font-size:13px;
                }
            }
        }

        button:first-child {
            margin-right: 1rem;
        }

        @include for_breakpoint(xs) {
            margin-top: 2em;
            margin-bottom: 4em;

            button:first-child {
                margin-right: 1rem;
                margin-bottom: 0;
            }

            @media (max-width: 443px) {
                button:first-child {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.btn-edit-info {
	color: #1c57a5;
	font-family: Lato;
	font-size: 16px;
	font-weight: normal;
	line-height: 23.2px;
}
