@import '../../styles/scss/base/palette.scss';
@import '../../styles/scss/base/breakpoints.scss';

.header-container-HeaderVA {
    background: none !important;

    .header-va-wrapper {
        margin-top: 15%;

        label {
            font-size: 44px;

            @include for_breakpoint(xs) {
                font-size: 30px;
            }
        }
    }
    .no-wrap {
        white-space: nowrap;
    }
}