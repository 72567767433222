@import '../../styles//scss//base/palette.scss';
@import '../../styles/scss/base/breakpoints.scss';

.analyzer-info-wrapper {
    .header-breadcrumbs-wrapper {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .info-title {
        white-space: nowrap;
        margin-bottom: 10px;
        font-family: TiemposHeadlineSemibold;
        font-size: 32px;
        color: $analyzerTitleFontColor;

        @media (max-width: 1000px) {
            white-space: normal;
        }
    }

    .view-results-button {
        @include for_breakpoint(xs) {
            width: 100%;
        }

        &:disabled { 
            background-color: $neptune !important;
            color: $white;
            opacity: unset;
        }
    }

    .view-results-button-wrapper {
        width: fit-content;
        position: relative;
        /* EDGE */
        @supports (-ms-ime-align: auto) {
            display: table;
        }

        @include for_breakpoint(xs) {
            width: 100%;
        }
    }

    .circle-progress {
        color: white;
        position: relative;
        margin-right: 6px;
    }
}

/* IE11 */
_:-ms-fullscreen,
:root .analyzer-info-wrapper {
	.view-results-button-wrapper {
		display: table;
	}
}
