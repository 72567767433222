@import '../../../styles/scss/base/palette';
@import '../../../styles/scss/base/breakpoints.scss';

.nav-menu {
	background-color: $navColor !important;
	height: 75px;
    line-height: 75px;
    
    .container {
        padding-right: 15px;
        padding-left: 15px;
    }

	@media (min-width: 1200px) {
        .container {
            max-width: 1140px !important;
        }
	}
}

.icon-button {
	margin-top: -7px !important;
	margin-bottom: 12px;
	padding-top: 0px;
	padding-bottom: 0px;
	border-radius: 0px !important;
}

.nav-bar-title {
	margin-left: 20px !important;
	margin-top: 3px !important;
	margin-bottom: 3px !important;
	font-size: 16px !important;
	font-family: Gotham-Medium !important;
	font-weight: normal;
	color: #fff !important;
}

.nav-bar-right-options {
	margin-left: auto;
	margin-top: 6px;
	margin-bottom: 6px !important;
	font-size: 16px;
	font-family: Gotham-Book !important;
	color: #fff !important;

	span.divisor {
		margin-left: 12px;
		margin-right: 12px;
	}
}

.popper-menu-mobile {
	padding-top: 75px;
	position: relative;
	border: none;
	box-shadow: none !important;
	color: #fff !important;
	background-color: $funBlue !important;
}

.text-menu-mobile {
	text-transform: uppercase;
}

@media only screen and (max-width: 1010px) {
	.nav-bar-title {
		display: none;
	}
}

@include for_breakpoint(xs) {
	.nav-bar-right-options {
		display: none;
	}
}

@media only screen and (min-width: 575px) {
	.burger-icon {
		display: none;
	}
}

@media only screen and (max-width: 576px) {
	.burger-icon {
		display: block;
	}
}
