@import '../../styles/scss/base/palette.scss';
@import '../../styles/scss/base/breakpoints.scss';

.layout-content {

    @media (max-width: 600px) {
        min-height: 600px;
    }
}

.not-found-wrapper {
    padding: 4rem 35px 0 35px;

    @media (max-width: 600px) {
        padding-top: 2rem;
    }

    .animation-container {
        max-height: 365px;
        max-width: 410px;
        height: 228px;
        width: 228px;
        margin: auto;

        @media (max-width: 600px) {
            max-height: 275px;
            max-width: 230px;
        }
    }

    .not-found-section {
        text-align: center;
    }

    .title {
        color: $btnBlue;
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        margin-bottom: 10px;
        font-family: "ProximaNova-Regular";
    }


    .description {
        color: $codGray;
        font-size: 22px;
        margin-bottom: 5px;
        font-family: "ProximaNova-Regular";

        @media (max-width: 600px) {
            font-size: 16px;
        }
    }

    .instruction-description {

        @media (max-width: 600px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @media (max-width: 622px) and (min-width: 600px){
            padding-left: 70px;
            padding-right: 70px;
        }
    }

    .not-found-button {
        max-width: 193px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        padding: 0px;
        font-family: "ProximaNova-Regular";
        background-color: $btnBlue;
    }

    .m-b-25 {
        margin-bottom: 25px;
    }
}