@import '../../styles/scss/base/palette.scss';
@import '../../styles/scss/base/breakpoints.scss';

.home-content {
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh);
    width: 100%;
    position: relative;
    min-height: 778px;

    .get-started-button {
        width: 232px;
    }

    h1,
    h2,
    p {
        color: $white;
        font-weight: normal;
    }

    h1 {
        font-family: TiemposHeadlineSemibold;
        font-size: 32px !important;
    }

    h2 {
        margin-bottom: -4px;
        font-family: TiemposHeadlineSemibold;
        font-size: 44px !important;
        ;

        @media (max-width: 575px) {
            margin-bottom: 0px;
        }
    }

    p {
        margin-bottom: 26px;
        margin-left: 3px;

        &.animated-p {
            max-width: 700px;
        }

        &.disclaimer {
            margin-top: 26px;
            font-size: 12px;
        }
    }

    #divider {
        height: 1px;
        width: 1px;
        margin-bottom: 35px;
        margin-top: 15px;
        border-bottom: 1px solid $white;
        -webkit-animation: increase 2s;
        -moz-animation: increase 2s;
        -o-animation: increase 2s;
        animation: increase 1.7s;
        animation-fill-mode: forwards;
    }

    @keyframes increase {
        100% {
            width: 100%;
        }
    }

    &-items {
        position: relative;
        top: 200px;

        @media (max-width: 750px) {
            top: 100px
        }

        @media (max-width: 575px) {
            text-align: center !important;
        }

        @include for_breakpoint(xl) {
            max-width: 1140px !important;
        }
    }

    @media (max-width: 1050px) and (min-width: 991px) {
        background-position: 100% 100%;
        min-height: 820px;
    }

    @include for_breakpoint(md) {
        background-position: 100% 100%;
        min-height: 860px;
    }

    @include for_breakpoint(sm) {
        background-position: 100% 100%;
        min-height: 900px;
    }

    @include for_breakpoint(xs) {
        background-position: 75% 100%;
        min-height: 960px;
    }

    @media (max-width: 400px) {
        min-height: 1060px;
    }
}


