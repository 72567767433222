@import '../../../styles/scss/base/palette.scss';

.results-bar-char-wrapper {
    width: 100%;
    height: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.13);

    .bar-value-label {
        font-family: Gotham-Book;
        font-size: 16px;
        font-weight: normal;

        &-outside {
            fill: $shark;
            font-family: Gotham-Book;
            font-size: 16px;
            font-weight: normal;
        }
    }
}

/* IE11 */
_:-ms-fullscreen,
:root .results-bar-char-wrapper {
    height: 110px !important;

    @media only screen and (max-width: 992px) {
        height: 81px !important;
    }

    @media screen and (max-width: 768px) {
        height: 71px !important;
    }
}