@import '../../../styles/scss/base/breakpoints.scss';
@import '../../../styles/scss/base/palette.scss';

.header-cards-comparison-wrapper {
    .row-content {
        position: relative;
    }

    .header-card {
        border-radius: 4px;
        min-height: 149px;
        position: relative;

        > div {
            color: $white;
            font-family: Gotham-Book;
            font-size: 20px;
            font-weight: normal;
            position: absolute;
            float: left;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 80%;
            margin-bottom: 0px;

            @include for_breakpoint(xs) {
                font-size: 15px;
            }
        }

        svg {
            position: absolute;
            left: 0px;
        }

        @include for_breakpoint(xs) {
            min-height: 110px;
        }
    }

    .first-card {
        @extend .header-card;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(89, 116, 128, 0.37) 100%);

        &.fia-label {
            .percentage-label {
                text-align: center;
                .percent {
                    display: inline;
                    float: none;
                }
                .label {
                    display: inline;
                    float: none;
                    position: relative;
                    top: -10px;
                    left: 10px;
                    @include for_breakpoint(sm) {
                        display: block;
                        width: 100%
                    }
                    @include for_breakpoint(xs) {
                        display: block;
                        width: 100%
                    }
                }
            }
        }

        > div {
            .percent {
                width: 40%;
                float: left;

                &.long-text {
                    width: 100%;
                    text-align: center;
                }

                &.medium-text {
                    width: 47%
                }
            }

            .label {
                width: 50%;
                float: left;
                margin-top: 10px;

                &.long-text {
                    width: 100%;
                    text-align: center;
                    margin-top: 0;
                }
            }
        }

        @include for_breakpoint(sm) {
            margin-bottom: 1rem;
        }

        @include for_breakpoint(xs) {
            margin-bottom: 1rem;

            > div {
                .percent {
                    width: 100% !important;
                    text-align: center;
                }

                .label {
                    text-align: center;
                    width: 100%;
                    margin-top: 0;
                    font-size: 18px;
                    position: relative;
                    top: -10px;
                }
            }
        }

        @include for_breakpoint(md) {

            > div {
                .percent {
                    width: 100% !important;
                    text-align: center;
                }

                .label {
                    text-align: center;
                    width: 100%;
                    margin-top: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .second-card {
        @extend .header-card;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(89, 116, 128, 0.37) 100%);

        > div {
            .percent {
                width: 40%;
                float: left;
            }

            .label {
                width: 50%;
                float: left;
                margin-top: 10px;
            }
        }

        @include for_breakpoint(sm) {
            margin-bottom: 1rem;

            > div {
                .percent {
                    width: 35%;
                }
            }
        }

        @include for_breakpoint(xs) {

            > div {
                .percent {
                    width: 100%;
                    text-align: center;
                }

                .label {
                    text-align: center;
                    width: 100%;
                    margin-top: 0;
                    font-size: 18px;
                    position: relative;
                    top: -11px;
                }
            }
        }

        @include for_breakpoint(md) {

            > div {
                .percent {
                    width: 100%;
                    text-align: center;
                }

                .label {
                    text-align: center;
                    width: 100%;
                    margin-top: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .middle-and {
        width: 61px;
        height: 61px;
        box-shadow: 0 8px 10px rgba(48, 71, 82, 0.36);
        background-color: $white;
        border-radius: 100%;
        position: absolute;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        @include for_breakpoint(xs) {
            width: 50px;
            height: 50px;
        }

        span {
            opacity: 0.8;
            color: $pickledBluewood;
            font-family: Gotham-Book;
            font-size: 33px;
            font-weight: normal;
            position: absolute;
            float: left;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include for_breakpoint(xs) {
                font-size: 24px;
            }
        }
    }
}