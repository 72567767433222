@import '../base/palette.scss';
@import '../scss/base/breakpoints.scss';

html {
    font-size: 14px;
}

body {
    margin: 0px;
    min-width: 320px;
    font-weight: normal;
}

form {
    min-height: calc(100vh - 235px);
}

@media (min-width: 1200px) {
    .panel-container {
        width: 1200px;
    }
}


h1 {
    font-size: 80px !important;
    font-family: TiemposHeadlineSemibold;

    &.result-fia {
        font-size: 44px !important;
        color: $cobalt;
        line-height: 1;
        margin-bottom: 11px;
    }

    @media (max-width: 992px) {
        &.result-fia {
            font-size: 50px !important;
        }
    }
	@media (max-width: 768px) {
        font-size: 58px !important;
        &.result-fia {
            font-size: 40px !important;
        }
	}

	@include for_breakpoint(xs) {
        font-size: 34px !important;
	}
}

h2 {
    color: $funBlue;
    font-family: Gotham-Light;
    font-size: 32px !important;
    font-weight: normal !important;

    @include for_breakpoint(sm) {
        font-size: 36px !important;
    }

    @include for_breakpoint(xs) {
        font-size: 32px !important;
    }
}

h3 {
    color: $cobalt;
    font-family: TiemposHeadlineSemibold;
    font-size: 44px !important;
    font-weight: normal;
    margin-bottom: 0 !important;
    letter-spacing: -0.9px !important;
    line-height: 1 !important;

    &.result-fia {
        font-size: 32px !important;
    }

    @media (max-width: 992px) {
        &.result-fia {
            font-size: 38px !important;
        }
    }

    @media (max-width: 768px) {

        &.result-fia {
            font-size: 30px !important;
        }
    }

    @include for_breakpoint(xs) {
        &.result-fia {
            font-size: 28px !important;
        }
    }
}

p {
	font-family: Gotham-Book;
}


.container {
    @include for_breakpoint(xl) {
            max-width: 960px !important;
    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.bg-container {
    background-color: rgba(224, 225, 221, 0.2);
    margin-bottom: 40px;
    margin-left: 0 !important;
    padding-top: 1.8rem;
    padding-left: 1.8rem !important;
    padding-right: 0 !important;
    padding-bottom: 0.3rem!important;

    .years-of-deferral {
        font-size: 11px;
        color: #737373;
        position: absolute;
        top: 46px;
        left: 15px;
    }

    .pr-xs-0 {
        @include for_breakpoint(xs) {
            padding-right: 0.5rem !important;
        }
    }
}

label.form-section-title {
    color: $titleBlue;
    font-size: 24px;
    font-family: Gotham-Bold;
    font-weight: normal;

    @include for_breakpoint(xs) {
        font-size: 18px;
    }

    &.result-fia {
        color: $mineShaft;
        font-family: Gotham-Book;
        font-size: 24px;

        @media (max-width: 992px) {
            font-size: 20px !important;
        }

        @include for_breakpoint(xs) {
            font-size: 18px;
        }
    }

    &.result-va {
        font-family: Gotham-Light;
        font-size: 24px;

        @include for_breakpoint(xs) {
            font-size: 18px;
        }
    }
}

label.form-section-subTitle {
    color: $titleBlue;
    font-size: 15px;
    font-family: Gotham-Bold;
    font-weight: normal;
}

.error-message {
    color: #f44336;
    position: absolute;
    top: 47px;
    left: 15px;
    font-size: 11px;
}

.label-checkBox {
    position:relative;
    top: -4px;
    cursor:pointer;
}
.checkbox-label {
    margin-right: 0px !important;
}
.hint-no-roll-up {
    left: 170px !important;
    top: 18px !important;
    right:0 !important;
}

.breadcrumbs-link:hover {
    color: $cobalt !important;
}