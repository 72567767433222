@import '../../../styles/scss/base/palette.scss';

.autocomplete-combo-box-wrapper {

    ::-ms-clear {
        display: none;
    }

    .suggestions {
        position: absolute !important;
        z-index: 2;
        width: 95.5%;
        margin-top: 0.1em !important;
        max-height: calc((24px + 22px) * 5) !important;
        overflow: auto;
    }

    .autocomplete-container {
        position: 'relative'
    }

    .icons-container {
        padding-bottom: 2px;
        background-color: $white;
        pointer-events: all !important;
        cursor: pointer !important;

        &-disabled {
            cursor: default !important; 
        }
    }

    .MuiFilledInput-inputAdornedEnd {
        padding-right: 27px;
    }

    .MuiInputAdornment-positionEnd {
        margin-left: 0 !important;
    }

    .MuiInputBase-input:disabled  {
        cursor: default !important;
    }

    .MuiInputBase-input:disabled {
        background-color:$white;
    }
    
    .MuiMenuItem-root {
        font-family: Gotham-Book;
    }


    /* IE11 */
    @media screen and (-ms-high-contrast:active),
    screen and (-ms-high-contrast: none) {
        .MuiFilledInput-adornedEnd {
            padding-right: 0 !important;
        }

        input[type="time"]::-webkit-clear-button {
            display: none;
        }
    }

    .errors-hidden {
        p {
            display: none;
        }
    }
}