@import '../../../styles/scss/base/palette.scss';

.bar-chart-wrapper {
   height: 100%;
   width: 98%;
   margin-left: 3px;
   border-top-left-radius: 2%;
   border-top-right-radius: 2%;
   border-bottom-left-radius: 1%;
   border-bottom-right-radius: 1%;
   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.13);
   background-color: $porcelain;
}

.vertical-bar-chart-wrapper {
   @extend .bar-chart-wrapper;
}

.vertical-grouped-bar-chart-wrapper {
   @extend .bar-chart-wrapper;
}

.results-card-wrapper .card-wrapper {
   margin-bottom: 1rem;
   min-width: unset !important;
}