@import '../../styles/scss/base/breakpoints.scss';
@import '../../styles/scss/base/palette.scss';

.header-similar-options-wrapper {

    .header-cards-comparison-wrapper {
        .first-card {
            .percentage-label {
                .percent {
                    width: 35%;
                }
                .label {
                    width: 60%;
                }

                @include for_breakpoint(xs) {
                    .label { width: 100%; }
                }

                @include for_breakpoint(md) {
                   .label { width: 100%; }
                }
            }
        }
    }

    p {
        a {
            color: $white
        }
    }

    sub {
        vertical-align: top;
        font-size: 0.8rem;
        bottom: -1em;
    }

    .title-good-news {

        @include for_breakpoint(xs) {
            img {
                height: 60px;
            }
        }
    }

    .title-results {
        padding: 2% 0;
    }

    .title-news {
        margin-left: 4rem;

        @include for_breakpoint(sm) {
            margin-left: 0rem;
        }

        @include for_breakpoint(xs) {
            margin-left: 0rem;
        }
    }

    .header-list {
        padding-top: 2%;
        padding-bottom: 2%;

        ol {
            list-style: none;
            counter-reset: item;
            padding-left: 0px;
        }

        li {
            counter-increment: item;
            margin-bottom: 5px;
            color: $mineShaft;
            font-family: Gotham-Book;
            font-size: 16px;
            font-weight: normal;

            @include for_breakpoint(xs) {
                font-size: 11px;
            }
        }

        li:before {
            color: $mineShaft;
            font-family: Gotham-Book;
            font-size: 11px;
            font-weight: normal;
            padding-top: 1px;
            vertical-align: text-top;
            content: counter(item);
            width: 1.2em;
            text-align: center;
            display: inline-block;
        }
    }
}