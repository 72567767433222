@import '../../../styles/scss/base/palette.scss';

.results-card-wrapper {
    margin-bottom: 4rem;

    .card-wrapper {
        margin-bottom: 1rem;
        min-width: 600px;

        .chart-divider {
            height: 1px;
            width: 100%;
            background-color: rgba(28, 87, 165, 0.5);
        }
    }
}

/* IE11 */
_:-ms-fullscreen,
:root .results-card-wrapper-vertical {
    .vertical-bar-chart-wrapper {
        height: 698px;
    }

    .card-wrapper {
        min-width: auto !important;
    }

    .vertical-grouped-bar-chart-wrapper {
        height: 704px;
    }
}