@import '../../../styles/scss/base/breakpoints.scss';

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.88);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	&-visible {
		z-index: 1302;
	}
}
