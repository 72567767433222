$white: #ffffff;
$mineShaft: #333333;
$abbey: #767676;
$funBlue: #1C57A5;
$celeste: #c9cac8;
$desertStorm: #F2F2F0;
$chambrayBlue: #346094;
$astral: #337AB7;
$havelockBlue: #599DD8;
$matisse: #1D5F98;
$calypso: #285C75;
$pickledBluewood: #253746;
$athensGray: #EEF2F4;
$titleBlue: #003b5c;
$cornFlowerBlue: #aec5cf;
$nobel: #b6b6b6; 
$shark: #212529;
$porcelain: #F2F3F4;
$codGray: #222222;
$hoverBlue: #0072CF;
$btnBlue: #0067c5;
$cobalt: #0047bb;
$navColor: $cobalt;
$gray80: #cccccc;
$analyzerTitleFontColor: $cobalt;
$deepSea: #008574;
$downy: #6eceb2;
$neptune: #72b0bd;
$bunting: #141b4d;
$darkCelurian: #005670;