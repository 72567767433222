@import '../../styles/scss/base/breakpoints.scss';
@import '../../styles/scss/base/palette.scss';

.continue-modal-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@media (max-width: 991px) {
		align-items: flex-start;
		overflow-y: auto;
	}
	.modal-title {
		margin-top: 20px;
		@media (max-width: 991px) {
			font-size: 22px;
			padding-left: 15px;
		}
		@media (max-width: 575px) {
			text-align: left !important;
			font-size: 17px
		}
	}
	.modal-content {
		background-color: $desertStorm;
		max-width: 90%;
		min-width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		@include for_breakpoint((lg, xl)) {
			max-width: 90%;
		}

		@media (min-width: 1200px) {
			max-width: 1200px;
		}
		@include for_breakpoint(md) {
			top: 10%;
		}
	}
	.modal-content-buttons {
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		margin-bottom: 20px;
		.continue-button {
			margin-right: 1rem;
		}
		@media (max-width: 991px) {
			margin-top: 2em;
			margin-bottom: 4em;

			.continue-button {
				margin-right: 1rem;
				margin-bottom: 0;
			}

			@media (max-width: 464px) {
				padding: 10px;
				.continue-button {
					margin-bottom: 1rem;
					width: 100%;
				}
				.cancel-button {
					width: 100%;
				}
			}
		}
	}
	.choose-reason {
		position: absolute;
		left: 0px;
		top: 0;
		@media (max-width: 575px) {
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
	.modal-content-cards-container {
		background-color: $white;
		width: 94%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		border-radius: 4px 0 0 4px;
		border: 1px solid $cornFlowerBlue;
		display: flex;
		div.panel {
			flex-grow: 0.25;
			cursor: pointer;
			width: 100%;
			border-radius: 4px 0 0 4px;
			border-right: 1px solid $cornFlowerBlue;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			div.reason-icon {
				margin-top: 30px;
				@media (max-width: 991px) {
					float: left;
					margin-left: 50px;
					margin-bottom: 30px;
				}
				@media (max-width: 575px) {
					display: none;
				}
			}
			@media (max-width: 575px) {
				height: 100px;
			}
		}
		div.panel:last-child {
			border: none;
		}
		@media (max-width: 991px) {
			flex-direction: column;
			div.panel {
				border-right: none;
				border-bottom: 1px solid #aec5cf;
				border-radius: 0;
				display: inline;
			}
		}
	}
	p.light-text {
		font-family: Gotham-Book;
		font-size: 20px;
		color: #184154;
		max-width: 90%;
		text-align: center;
		margin-top: 20px;
		&.last-text {
			margin-top: 50px;
			span {
				color: #476776;
			}
		}
		@media (max-width: 991px) {
			margin: 0 18px 0 170px;
			position: absolute;
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			text-align: left;
			&.last-text {
				margin-top: 0px;
			}
		}
		@media (max-width: 575px) {
			margin: 0 18px 0 50px;
			font-size: 18px;
		}
	}
}
