@import '../../../styles/scss/base/palette.scss';
@import '../../../styles/scss/base/breakpoints.scss';

.layout-common-footer {
	min-height: 75px;
	text-align: center;
	bottom: 0px;
	line-height: 75px;
	height: 75px;
	background-color: $desertStorm;
	color: $abbey;
	font-size: 15px;
	width: 100%;
}

.layout-result-footer {
	background-color: $desertStorm;
	color: $abbey;
	font-size: 13px;
	width: 100%;
}
.content-result-footer {
	padding-top: 59px;
	padding-bottom: 53px;
}

.bold-text {
	font-family: 'Gotham-Bold' !important;
}
.lowercase {
	text-transform: lowercase;
}
.content-common-footer {
    padding: 0;

    p {
        margin-bottom: 0px;
        font-size: 13px;
        font-family: Gotham-Book;

        .no-wrap {
            white-space: nowrap
        }

        @include for_breakpoint(xs) {
            font-size: 10px;
            padding: 0;
        }

        @media (max-width: 400px) {
            line-height: 20px;
            padding-top: 20px;
        }
    }
}
