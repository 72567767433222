@import '../../../styles/scss/base/palette.scss';
@import '../../../styles/scss/base/breakpoints.scss';

.user-agreement-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.user-agreement-group {
	height: 242px;
	width: 528px;

	@include for_breakpoint(mobile) {
		width: unset;
		padding: 15px;
	}
}

.user-agreement-group .form-section-title {
	color: $white;
	width: unset;
}

.user-agreement-group-content {
	color: $white;
	height: 130.25px;
	width: 500px;
	margin-bottom: 20px;

	@include for_breakpoint(mobile) {
		height: unset;
		width: unset;
	}
}

.user-agreement-group-options {
	width: 60%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	@include for_breakpoint(mobile) {
		width: unset;
		height: 50%;
		flex-direction: column-reverse;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.user-agreement-group-options button {
	color: $white;
	border-radius: 4px;
	border-color: transparent;
	padding: 7px 30px;
}

.cancel-button {
	border: 1px solid $white !important;
	background-color: transparent;
	width: 114px;

	@include for_breakpoint(mobile) {
		width: 172px;
	}
}

.agree-button {
    background-color: $funBlue;
    width: 172px;

    &:hover {
        background-color: $hoverBlue;
        border-color: $hoverBlue;
    }
}
