@import '../../../styles/scss/base/palette';

.layout-content {
    min-height: calc(100vh - 80px);
    justify-content: center;
    background-color: $white;
}

@media (min-width: 1200px) {
    .layout-content {
        display: block;
    }
}
