$size__site_content_width: 1024px;

/* Media Queries */
$media_queries : ('mobile' : "only screen and (max-width: 667px)",
    'tablet' : "only screen and (min-width: 668px) and (max-width: $size__site_content_width)",
    'desktop' : "only screen and (min-width: ($size__site_content_width + 1))",
    'retina2' : "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
    'retina3' : "only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)",
    'landscape' : "screen and (orientation:landscape) ",
    'portrait' : "screen and (orientation:portrait) ",
    'xs': '(max-width: 575px)',
    'sm': '(min-width: 576px) and (max-width: 767px)',
    'md': '(min-width: 768px) and (max-width: 991px)',
    'lg': '(min-width: 992px) and (max-width: 1199px)',
    'xl': '(min-width: 1200px)');

@mixin for_breakpoint($breakpoints) {
    $conditions: ();

    @each $breakpoint in $breakpoints {
        // If the key exists in the map
        $conditions: append($conditions,
            #{inspect(map-get($media_queries, $breakpoint))},
            comma);
    }

    @media #{$conditions} {
        @content;
    }

}